import './Language.css';
import {useTranslation} from "react-i18next";
import {flags, languagesMap} from "./LangUtils";
import {useEffect, useState} from "react";
import Select, {components, SingleValueProps} from 'react-select';


interface LanguageProps {
    setLanguage: (language: string) => void,
    availableLanguages: string[],
}

interface LanguageOption {
    readonly value: string;
    readonly label: string;
    readonly flag: any;
}

const Language = ({setLanguage, availableLanguages}: LanguageProps) => {
    const {i18n, t} = useTranslation<string, string>();
    const savedLanguage = {
        value: i18n.language,
        label: i18n.language,
        flag: flags.get(i18n.language)
    };
    const [options, setOptions] = useState<LanguageOption[]>()

    const switchLanguage = ({lng}: { lng: any }) => {
        i18n.changeLanguage(lng);
        setLanguage(languagesMap.get(lng)!!)
    };

    useEffect(() => {
        if (availableLanguages.length > 0 && !availableLanguages.includes(savedLanguage.value)) {
            let firstLanguage = availableLanguages[0];
            i18n.changeLanguage(firstLanguage);
            setLanguage(languagesMap.get(firstLanguage)!!)
        }
        setOptions(availableLanguages.map((language) => {
            return {
                value: language,
                label: language,
                flag: flags.get(language)
            }
        }));
    }, [availableLanguages, i18n, savedLanguage.value, setLanguage]);

    return (
        <>
            {availableLanguages && availableLanguages.length > 1 && <Select
                styles={{
                    control: (base) => ({
                        ...base,
                        borderRadius: '15px',
                    }),
                    singleValue: (base) => ({
                        ...base,
                        display: 'flex',
                    }),
                }}
                isSearchable={false}
                components={{SingleValue: ControlComponent}}
                onChange={(selectedOption) => switchLanguage({lng: selectedOption?.value})}
                defaultValue={savedLanguage}
                options={options}
                formatOptionLabel={(data) => {
                    return (
                        <div style={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}>
                            <img src={data.flag} width='30px' height='20px' alt={t(data.label)}/>
                            <div style={{fontSize: '12px' , margin: 'auto'}}>{t(data.label)}</div>
                        </div>)
                }}
            />
            }
        </>
    );
};


const ControlComponent = (props: SingleValueProps<LanguageOption, false>) => (
    <components.SingleValue {...props}  >
        <img src={props.data.flag} width='40px' height='20px' alt={props.data.label}/>
    </components.SingleValue>
);


export default Language;
