/* tslint:disable */
/* eslint-disable */
/**
 * digital-menu
 * OpenAPI design for a restaurant web application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddressDto } from './AddressDto';
import {
    AddressDtoFromJSON,
    AddressDtoFromJSONTyped,
    AddressDtoToJSON,
} from './AddressDto';

/**
 * this data type represent a Restaurant
 * @export
 * @interface RestaurantDto
 */
export interface RestaurantDto {
    /**
     * praimery key for Restaurant
     * @type {string}
     * @memberof RestaurantDto
     */
    id?: string;
    /**
     * Restaurant name
     * @type {string}
     * @memberof RestaurantDto
     */
    name: string;
    /**
     * Restaurant email
     * @type {string}
     * @memberof RestaurantDto
     */
    email: string;
    /**
     * Restaurant phone number
     * @type {string}
     * @memberof RestaurantDto
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof RestaurantDto
     */
    language?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RestaurantDto
     */
    languages?: Array<RestaurantDtoLanguagesEnum>;
    /**
     * 
     * @type {string}
     * @memberof RestaurantDto
     */
    logo?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RestaurantDto
     */
    orderEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RestaurantDto
     */
    banner?: string;
    /**
     * 
     * @type {string}
     * @memberof RestaurantDto
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof RestaurantDto
     */
    headerText?: string;
    /**
     * 
     * @type {string}
     * @memberof RestaurantDto
     */
    theme?: string;
    /**
     * 
     * @type {AddressDto}
     * @memberof RestaurantDto
     */
    address?: AddressDto;
}


/**
 * @export
 */
export const RestaurantDtoLanguagesEnum = {
    Arabic: 'ARABIC',
    English: 'ENGLISH',
    French: 'FRENCH'
} as const;
export type RestaurantDtoLanguagesEnum = typeof RestaurantDtoLanguagesEnum[keyof typeof RestaurantDtoLanguagesEnum];


/**
 * Check if a given object implements the RestaurantDto interface.
 */
export function instanceOfRestaurantDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "email" in value;

    return isInstance;
}

export function RestaurantDtoFromJSON(json: any): RestaurantDto {
    return RestaurantDtoFromJSONTyped(json, false);
}

export function RestaurantDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RestaurantDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'email': json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'languages': !exists(json, 'languages') ? undefined : json['languages'],
        'logo': !exists(json, 'logo') ? undefined : json['logo'],
        'orderEnabled': !exists(json, 'orderEnabled') ? undefined : json['orderEnabled'],
        'banner': !exists(json, 'banner') ? undefined : json['banner'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'headerText': !exists(json, 'headerText') ? undefined : json['headerText'],
        'theme': !exists(json, 'theme') ? undefined : json['theme'],
        'address': !exists(json, 'address') ? undefined : AddressDtoFromJSON(json['address']),
    };
}

export function RestaurantDtoToJSON(value?: RestaurantDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'email': value.email,
        'phone': value.phone,
        'language': value.language,
        'languages': value.languages,
        'logo': value.logo,
        'orderEnabled': value.orderEnabled,
        'banner': value.banner,
        'currency': value.currency,
        'headerText': value.headerText,
        'theme': value.theme,
        'address': AddressDtoToJSON(value.address),
    };
}

