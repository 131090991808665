import {useTranslation} from "react-i18next";
import styled from "@emotion/styled";
import {apiBasePath} from "../../utils/ConfigUtils";
import {FontIcon} from "@react-md/icon";
import React from "react";
import {RestaurantDto} from "../../generated-sources/client";

interface ContactDialogProps {
    restaurant: RestaurantDto;
    onClose: () => void;
    buildBackground:()=> string;
}

interface BoxWrapperProps {
    buildBackground:()=> string;
}

const BoxWrapper = styled.div<BoxWrapperProps>`
    .contact-popup {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    .contact-popup-inner {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        max-width: 600px;
        background: white;
        border-radius: 10px;
        overflow-y: scroll;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }

    & .contact-banner {
        ${props => props.buildBackground()}
        padding-top: 0.5rem;
        position: relative;
        background-size: cover;
        min-height: 6rem;
        text-align: center;
        font-size: 1.25rem;
    }

    & .contact-banner .contact-description {
        font-family: "Bodoni MT", serif;
        text-transform: uppercase;
        font-size: 1rem;
    }

    .contact-close-btn {
        text-shadow: 2px 4px 10px  #666;
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        font-size: 30px;
        cursor: pointer;
        color: white;
    }

    .contact-profile-photo {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 5px solid white;
        border-radius: 50%;
        overflow: hidden;
        width: 100px;
        height: 100px;
        bottom: -110px;
    }

    & .contact-title {
        font-size: 30px;
        text-align: center;
        padding-top: 40px;
        font-weight: revert;
        color: #282A33
    }

    & .contact-subtitle {
        font-size: 15px;
        text-align: left;
        padding-top: 30px;
        padding-left: 35px;
        font-weight: 500;
        letter-spacing: 2px;
        color: #656565
    }

    & .contact-separator {
        border-top: 1px solid #c7c0c0;
        margin-left: 30px;
        margin-right: 30px;
    }

    & .contact-separator_fin {
        border-top: 1px solid #dfdfdf;
        margin-left: 30px;
        margin-right: 30px;
        margin-top: 10px;
    }
    
    & .contact-separator_footer{
        margin-top: 30px;
    }
    }

    & .contact-bottom-card {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding-top: 30px;
        align-items: center;
    }

    & .contact-price {
        margin-left: 10px;
    }

    & .contact-copyright {
        text-align: center;
        color: black;
        margin-right: 10px;
    }

    & .contact-next-menu {
        text-align: center;
        color: #f98b48;
    }

    & .contact-phone-number {
        font-weight: 500;
    }
    & .contact-phone-title {
        font-weight: 500;
        text-align: left;
        color: slategrey;
    }
    & .contact-footer {
        border-top: 1px solid #dfdfdf;
        display: flex;
        flex-direction: row;
       margin-top: auto;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50px;
        background-color: #efefef;
        color: white;
        text-align: center;
        line-height: 50px;
        justify-content: center;
    }
    
    & .contact-btn-add {
        margin-left: 35px;
        background-color: #f98b48;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        width: 50px;
        height: 50px;
    }


    .contact-profile-photo img {
        width: 100%;
        height: 100%;
        object-fit: cover;
`;

export default function DialogContactView({
                                              onClose,
                                              restaurant,
                                              buildBackground
                                          }: ContactDialogProps) {

    const {t} = useTranslation<string, string>();

    return (
        <BoxWrapper buildBackground={buildBackground}>
            <div className="contact-popup" onClick={onClose}>
                <div className="contact-popup-inner" onClick={event => event.stopPropagation()}>
                    <div className={'contact-banner global-banner'}>
                        <span className={'contact-description'}>{restaurant?.headerText}</span>
                        <div hidden={!restaurant.logo} className="contact-profile-photo">
                            <img src={apiBasePath + restaurant.logo} alt="logo"/>
                        </div>
                    </div>
                    <h2 className={'contact-title'}>{restaurant?.name}</h2>
                    <div>
                        <div className={'contact-separator'}></div>
                        <div className={'contact-subtitle'}>{t("Contact informations")}</div>
                    </div>
                    <div className={'contact-bottom-card'}>
                        <button className={'contact-btn-add'}>
                            <FontIcon style={{color: 'white'}}>phone</FontIcon>
                        </button>
                        <div className={'contact-price'}>
                            <div className={'contact-phone-title'}>{t("Phone")}</div>
                            <a style={{textDecoration: 'none', color: 'inherit'}} target="_blank" rel="noreferrer"
                               href={'tel:' + restaurant?.phone}
                               className={'contact-phone-number'}>{restaurant?.phone}</a>
                        </div>
                    </div>
                    <div className={'contact-separator_fin'}></div>

                    <div className={'contact-bottom-card'}>
                        <button className={'contact-btn-add'}>
                            <FontIcon style={{color: 'white'}}>mail</FontIcon>
                        </button>
                        <div className={'contact-price'}>
                            <div className={'contact-phone-title'}>{t("E-mail")}</div>
                            <a style={{textDecoration: 'none', color: 'inherit'}} target="_blank" rel="noreferrer"
                               href={'mailto:' + restaurant?.email} className={'contact-phone-number'}>{restaurant?.email}</a>
                        </div>
                    </div>
                    <div className={'contact-separator_fin'}></div>

                    <div className={'contact-bottom-card'}>
                        <button className={'contact-btn-add'}>
                            <FontIcon style={{color: 'white'}}>location_on</FontIcon>
                        </button>
                        <div className={'contact-price'}>
                            <div className={'contact-phone-title'}>{t("Address")}</div>
                            <a style={{textDecoration: 'none', color: 'inherit'}} target="_blank" rel="noreferrer"
                               href={'https://www.google.com/maps/search/?api=1&query=' + restaurant?.address?.gpsLocation}
                               className={'contact-phone-number'}>{restaurant?.address?.street + ' ' + restaurant?.address?.town +
                                ' ' + restaurant?.address?.city + ' ' + restaurant?.address?.postalCode + ' ' + restaurant?.address?.country?.name}</a>
                        </div>
                    </div>
                    <button className="contact-close-btn" onClick={onClose}>
                        <FontIcon style={{color: 'white', fontSize: 45}}>{"close"}</FontIcon>
                    </button>
                    <div className='contact-separator_footer'></div>
                    <div className={'contact-footer'}>
                        <div className={'contact-copyright'}>POWERED BY</div>
                        <div className={'contact-next-menu'}>NEXT-MENU</div>
                    </div>
                </div>
            </div>
        </BoxWrapper>
    );

}