import {Outlet} from "react-router-dom";
import { useEffect, useState } from "react";
import { findTheme } from "../../service/ClientConnect";
import { ThemeDto } from "../../generated-sources/client";
import "./themes.css"
import ScrollToAnchor from "../scroll/ScrollToAnchor";

export function GlobalLayout() {
    const [theme, setTheme] = useState<string | null>(null)
    useEffect(() => {
        findTheme().then(
            (data: ThemeDto) => {
                if(data?.value){
                    setTheme(data.value)
                } else {
                    setTheme('ORANGE')
                }
            }
        ).catch(err => {
            setTheme('ORANGE')
            console.log(err.response);})
    }, [])
    return <>
        {theme &&
            <div className={theme}>
                <Outlet/>
            </div>
        }
        <ScrollToAnchor/>
    </>

}