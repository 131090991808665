import ar from "../../assets/i18n/flags/ar.svg";
import en from "../../assets/i18n/flags/en.svg";
import fr from "../../assets/i18n/flags/fr.svg";

export const languagesMap: Map<string, string> =
    new Map([
        ["fr", "FRENCH"],
        ["ar", "ARABIC"],
        ["en", "ENGLISH"]
    ]);
export const inverseLanguagesMap: Map<string, string> =
    new Map([
        ["FRENCH", "fr"],
        ["ARABIC", "ar"],
        ["ENGLISH", "en"]
    ]);

export const flags: Map<string, any> =
    new Map([
        ["ar", ar],
        ["fr", fr],
        ["en", en]
    ]);