import React, {useState} from "react";
import {RouterProvider} from "react-router-dom";
import {LanguageContext} from "./contexts/LanguageContext";
import {router} from "./router";
import {useTranslation} from "react-i18next";
import {languagesMap} from "./components/language/LangUtils";
import {GlobalParams, GlobalParamsContext} from "./contexts/GlobalParamsContext";

export function App() {
    const {i18n} = useTranslation<string, string>();
    const [language, setLanguage] = useState<string>(languagesMap.get(i18n.language)!!)
    const [availableLanguages, setAvailableLanguages] = useState<string[]>([])
    const [globalParams, setGlobalParams] = useState<GlobalParams>({
        currency: "DT",
        orderEnabled: false
    });
    return (
        <React.StrictMode>
            <LanguageContext.Provider value={{
                currentLanguage: language,
                setLanguage: setLanguage,
                availableLanguages: availableLanguages,
                setAvailableLanguages: setAvailableLanguages
            }}>
                <GlobalParamsContext.Provider value={{globalParams: globalParams, setGlobalParams: setGlobalParams}}>
                    <RouterProvider router={router}/>
                </GlobalParamsContext.Provider>
            </LanguageContext.Provider>
        </React.StrictMode>
    );
}