import { HomeCategoryDto } from "../../generated-sources/client";
import { useContext, useEffect, useState } from "react";
import { findHomeCategories } from "../../service/ClientConnect";
import styled from "@emotion/styled";
import { FontIcon } from "@react-md/icon";
import { HomeMenuItemCard } from "../../components/HomeMenuItemCard";
import { LanguageContext } from "../../contexts/LanguageContext";
import { useRtl } from "../../hook/useRtl";

const MenuWrapper = styled.div`
    .category-title {
        margin-top: 25px;
        margin-bottom: 15px;
        width: 100%;
        display: flex;
        align-content: start;
    }
    
    .item-grid {
        display: flex;
        gap: 10px;
        overflow-x: auto;
        scrollbar-width: none;
        overflow-scrolling: touch;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
`;

export function HomeCategories() {

    const [categories, setCategories] = useState<HomeCategoryDto[]>()
    const { currentLanguage } = useContext(LanguageContext);
    const { flexStyle } = useRtl();
    useEffect(() => {
        findHomeCategories(currentLanguage)
            .then((categories) => setCategories(categories))
    }, [currentLanguage]);

    const buildTitle = (category: HomeCategoryDto) => {
        return <>
            {category.icon && <FontIcon>{category.icon}</FontIcon>}
            <span style={{ marginRight: "10px", marginLeft: "10px" }}>{category.name}</span>
        </>;
    }

    return (
        <MenuWrapper>
            {categories && categories.length > 0 ? (
                categories.map((category: any) => (
                    <div key={category.id}>
                        <h2 className="category-title" style={flexStyle}>
                            {buildTitle(category)}
                        </h2>
                        <div style={flexStyle} className="item-grid">
                            {category.items.map((item: any) => (
                                <HomeMenuItemCard key={item.id} menuItem={item} />
                            ))}
                        </div>
                    </div>
                ))
            ) : null}
        </MenuWrapper>
    )

}