import {createContext} from 'react';

interface GlobalParams {
   orderEnabled : Boolean;
   currency: string ;

}
type GlobalContextProps = {
    globalParams: GlobalParams;
    setGlobalParams: (globalParams: GlobalParams) => void,
};
const GlobalParamsContext = createContext<GlobalContextProps>(
    {} as GlobalContextProps
);

export {GlobalParamsContext};
export type { GlobalParams };

