import styled from "@emotion/styled";
import {apiBasePath} from "../utils/ConfigUtils";
import {MenuItemDto, TagDto} from "../generated-sources/client";
import {useState} from "react";
import {DiscountInfoPopUp} from "./discount/DiscountInfoPopUp";
import {DiscountFlag} from "./discount/DiscountFlag";
import {Price} from "./price/Price";
import {getDiscount} from "../utils/DiscountUtils";
import {useRtl} from "../hook/useRtl";
import {Tag} from "./Tag";


const BoxWrapper = styled.div`
    background-color: rgba(255, 255, 255, 1);
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    padding-bottom: 10px;

    & .image {
        background-color: black;
        width: 100%;
        border-radius: 10px 10px 0 0;
        position: relative;
    }

    & .tags {
        position: absolute;
        top: 14px;
        right: 12px;
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        flex-direction: row-reverse;
    }
    
    & .title {
        font-weight: bold;
        font-family: Sans-serif, Poppins, serif;
    }

    & .content {
        padding: 0 20px;
    }

    & .description {
        font-weight: lighter;
        font-size: 0.9rem;
        font-family: Sans-serif, Poppins, serif;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        
    }

    & .bottom-card {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    & .discountFlag {
        position: absolute;
        bottom: -6px;
        left: 20px;
    }
`;

interface MenuItemCardParams {
    style?: any;
    onClick?: any;
    menuItem: MenuItemDto;
}

export function MenuItemCard({style, onClick, menuItem}: MenuItemCardParams) {
    const {flexStyle, textStyle} = useRtl();
    const [showDiscountInfo, setShowDiscountInfo] = useState(false);
    const currentDiscount = getDiscount(menuItem);

    return (
        <BoxWrapper style={style} onClick={onClick}>
            <div style={{position: "relative"}}>
                <img className={'image'} src={apiBasePath + menuItem.image} alt={"menu"}/>
                {currentDiscount &&
                    <DiscountFlag className="discountFlag" discount={currentDiscount}
                                  onClick={() => setShowDiscountInfo(true)}/>}
                <div className="tags">
                    {menuItem.tags?.map((tag: TagDto) => (
                        <Tag key={tag.id} tag={tag}/>
                    ))}
                </div>
            </div>
            <div className={'content'}>
                <div className={'bottom-card'} style={flexStyle}>
                    <p className={'title'}>{menuItem.name}</p>
                    <Price discount={currentDiscount} price={menuItem.price}/>
                </div>
                <div className={'description'} style={textStyle}>
                    {menuItem.description}
                </div>
            </div>
            {currentDiscount && showDiscountInfo &&
                <DiscountInfoPopUp discount={currentDiscount} closeDialog={() => setShowDiscountInfo(false)}/>}
        </BoxWrapper>
    )
}


