import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { readRestaurantIdentifiers, RestaurantId } from "../../service/StorageService";
import { useTranslation } from "react-i18next";

export function RestaurantsView() {
    const [restaurants, setRestaurants] = useState<RestaurantId[]>()
    const {t, i18n} = useTranslation<string, string>();
    useEffect(() => {
        const savedRestaurants = readRestaurantIdentifiers();
        setRestaurants(savedRestaurants);
    }, []);
    return (
        <>
            <header style={{ padding: '10px', backgroundColor: '#f8f9fa', textAlign: 'center', marginBottom: '10px' }}>
                <h1>Next-Menu</h1>
            </header>
            <div style={{ padding: '10px'}}>
                {restaurants && restaurants.length > 0 ? (
                    <div>
                        <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>{t('Visited Restaurants')}</h2>
                        <ul style={{ listStyleType: 'none', padding: 0 , direction: i18n.dir() }}>
                            {restaurants.map((restaurant, index) => (
                                <li key={index} style={{ marginBottom: '10px' }}>
                                    <NavLink
                                        to={'/' + restaurant.encodedRestaurantIdentifier}
                                        style={{
                                            display: 'block',
                                            padding: '10px 15px',
                                            backgroundColor: '#f9f9f9',
                                            borderRadius: '5px',
                                            textDecoration: 'none',
                                            color: '#333',
                                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                                        }}
                                    >
                                        {restaurant.name}
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: '20px' }}>
                        <div style={{ width: '100%', maxWidth: '400px', height: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                            <div style={{ width: '100px' }}></div>
                            <DotLottieReact
                                src="/lotties/qrcode.lottie"
                                loop
                                autoplay
                            />
                        </div>
                        <h2 style={{ textAlign: 'center', marginBottom: '10px' }}>{t('Welcome to Next-Menu')}</h2>
                        <ol style={{ maxWidth: '350px', direction: i18n.dir() }}>
                            <li>{t('Open your smartphone\'s camera app.')}</li>
                            <li>{t('Point the camera at the QR code.')}</li>
                            <li>{t('Wait for a notification to appear with a link to the menu.')}</li>
                            <li>{t('Tap on the notification to open the menu.')}</li>
                        </ol>
                    </div>
                )}
            </div>

        </>
    );
}