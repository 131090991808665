import styled from "@emotion/styled";
import {apiBasePath} from "../utils/ConfigUtils";
import {MenuItemDto, TagDto} from "../generated-sources/client";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useRtl} from "../hook/useRtl";
import {Tag} from "./Tag";
import {useContext} from "react";
import {GlobalParamsContext} from "../contexts/GlobalParamsContext";

const BoxWrapper = styled.div`
    border-radius: 10px;
    max-width: 290px;
    min-width: 190px;
    width: 55vw;
    flex-shrink: 0;
    border: 1px solid #ccc;
    background-color: white;
    display: flex;
    flex-direction: column;

    & .image {
        background-color: black;
        width: 100%;
        height: 100%;
        max-height: 170px;
        border-radius: 10px 10px 0 0;
        position: relative;
    }

    & .tags {
        position: absolute;
        top: 14px;
        right: 12px;
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        flex-direction: row-reverse;
    }
    
    & .title {
        font-weight: bold;
        font-family: Sans-serif, Poppins, serif;
        margin-bottom: 7px;
    }

    & .content {
        padding: 10px;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: space-between;
    }

    & .description {
        font-weight: lighter;
        font-size: 0.9rem;
        font-family: Sans-serif, Poppins, serif;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 100%;

    }

    & .card {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    & .bottom-card {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 30px;
        align-items: center;
    }

    & .price {
        margin: 0;
        font-weight: bold;
        white-space: nowrap;
        display: flex;
        gap: 5px;
    }

    & .btn-add {
        padding: 0 15px;
        border-radius: 100px;
        border: none;
        cursor: pointer;
        min-width: 80px;
        max-height: 40px;
        height: 30px;
        font-weight: bold;
        color: white;
        display: flex;
        align-items: center;
        gap: 5px;
    }
`;

export function HomeMenuItemCard(props: any) {

    const navigate = useNavigate();
    const {t} = useTranslation<string, string>();
    const {flexStyle,flexAlignStyle} = useRtl();
    const {globalParams} = useContext(GlobalParamsContext)

    const openDetails = async (menuItem: MenuItemDto) => {
        navigate(`/categories/menuItems/${menuItem.id}/details`, {state: {menuItem: menuItem}});
    }
    return (
        <BoxWrapper style={props.style} onClick={() => openDetails(props.menuItem)}>
            <div className="image">
                <img src={apiBasePath + props.menuItem.image} alt={"menu"}
                     style={{width: '100%', height: '100%', borderRadius: '10px 10px 0 0'}}/>
                <div className="tags">
                    {props.menuItem?.tags.map((tag: TagDto) => (
                        <Tag key={tag.id} tag={tag}></Tag>
                    ))}
                </div>
            </div>
            <div className={'content'}>
                <div style={flexAlignStyle} className={'card'}>
                    <div className={'title'}>{props.menuItem.name}</div>
                    <div className={'description'}>
                        {props.menuItem.description}
                    </div>
                </div>
                <div style={flexStyle} className={'bottom-card'}>
                    <p className={'price color'}><span>{props.menuItem.price}</span><span>{t(globalParams.currency)}</span></p>
                    {globalParams.orderEnabled && <button style={flexStyle} className={'btn-add background-color'}><span>+</span><span>{t('Add')}</span></button>}
                </div>
            </div>
        </BoxWrapper>
    )
}


