import { Buffer } from 'buffer';
import { RestaurantIdentifierNotFoundException } from "../exceptions/RestaurantIdentifierNotFoundException";


interface RestaurantIdentifier {
    tenantId: string;
    restaurantId: string;
    tableId?: string | null;
}

export interface RestaurantId {
    name: string;
    encodedRestaurantIdentifier: string;
}

function addOrUpdateRestaurantToLocalStorage(restaurant: RestaurantId) {
    const restaurants = localStorage.getItem("restaurants");
    if (restaurants) {
        const restaurantArray = JSON.parse(restaurants) as RestaurantId[];
        const indexOf = restaurantArray
            .map(restaurant => restaurant.encodedRestaurantIdentifier)
            .indexOf(restaurant.encodedRestaurantIdentifier);
        if (indexOf > -1) {
            restaurantArray.splice(indexOf, 1);
        }
        restaurantArray.push(restaurant);

        while (restaurantArray.length > 10) {
            restaurantArray.shift();
        }

        localStorage.setItem("restaurants", JSON.stringify(restaurantArray));
    } else {
        localStorage.setItem("restaurants", JSON.stringify([restaurant]));
    }
}

export function saveRestaurantIdentifier(encodedRestaurantIdentifier: string) {
    let decodedJson = decodeBase64(encodedRestaurantIdentifier);
    let decodedIdentifier = JSON.parse(decodedJson) as RestaurantIdentifier;
    if (decodedIdentifier.tableId) {
        sessionStorage.setItem('tableId', decodedIdentifier.tableId);
    }
    if (decodedIdentifier.tenantId && decodedIdentifier.restaurantId) {
        sessionStorage.setItem('tenantId', decodedIdentifier.tenantId);
        sessionStorage.setItem('restaurantId', decodedIdentifier.restaurantId);
    } else {
        console.error('Invalid restaurant identifier');
    }
    addOrUpdateRestaurantToLocalStorage({
        name: 'Restaurant Name',
        encodedRestaurantIdentifier: encodeToBase64(JSON.stringify({ ...decodedIdentifier, tableId: null }))
    });
}

export function readCurrentRestaurantIdentifier(): RestaurantIdentifier {
    const tenantId = sessionStorage.getItem('tenantId');
    const restaurantId = sessionStorage.getItem('restaurantId');
    if (!tenantId || !restaurantId) {
        throw new RestaurantIdentifierNotFoundException();
    }
    return {
        tenantId: tenantId,
        restaurantId: restaurantId,
        tableId: sessionStorage.getItem('tableId')
    };
}


export function readRestaurantIdentifiers() {
    const restaurants = localStorage.getItem("restaurants");
    if (restaurants) {
        return JSON.parse(restaurants) as RestaurantId[];
    }
    return [];
}

export function updateRestaurantWithName(name: string) {
    const currentRestaurant = readCurrentRestaurantIdentifier();
    addOrUpdateRestaurantToLocalStorage({
        name: name,
        encodedRestaurantIdentifier: encodeToBase64(JSON.stringify({ ...currentRestaurant, tableId: null }))
    });
}


function decodeBase64(base64MetaData: string) {
    return Buffer.from(base64MetaData, 'base64').toString('utf-8');
}

function encodeToBase64(text: string) {
    return Buffer.from(text).toString('base64');
}


