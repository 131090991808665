import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import frTranslations from './assets/i18n/fr.json';
import arTranslations from './assets/i18n/ar.json';
import enTranslations from './assets/i18n/en.json';



const resources = {
    fr: {
        translation: frTranslations,
    },
    ar: {
        translation: arTranslations,
    },
    en: {
        translation: enTranslations
    }
};


i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        detection: {
            convertDetectedLanguage: (lang) => lang.split('-')[0]
        },
        fallbackLng: 'en',
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: resources
    });


export default i18n;